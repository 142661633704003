<template>
  <footer class="footer">
    <img src="@/assets/white-logo.png" alt="株式会社えにしす" class="footer-logo">
    <p>
      <a href=""><img src="@/assets/icon-instagram.svg" alt="株式会社えにしす" class="footer-instagram"></a>
    </p>
    <p>
      貴方様と弊社とのご縁が繋がることにより
      <br>貴方様にさらなる幸せと豊かな未来が
      <br>訪れますように…
    </p>
    <p class="number">
      <img src="@/assets/call.svg" class="call-icon">03-3527-2664
    </p>
    <p>営業時間 10:00 〜 良い物件と出会えるまで</p>
    <p>
      メールでのお問い合わせは<a href="mailto:info@enisys-realestate.jp" class="mail">こちら</a>
    </p>
    <small>2019 © 株式会社エニシス</small>
  </footer>
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>

<style scoped lang="scss">
.footer {
  padding-bottom: 1rem;
  background-color: #c0272d;
  text-align: center;
  color: white;

  &-instagram {
    width: 30px;
    margin-top: .5em;
    margin-bottom: .5em;
  }

  p {
    margin-bottom: 0.3rem;
  }

  .footer-logo {
    width: 80px;
    margin-top: 2rem;
  }

  .mail,
  .qr {
    color: white;
  }
}

.number {
  font-size: 1.6rem;
}

.call-icon {
  width: 1.4rem;
  margin-right: 0.5rem;
}
</style>