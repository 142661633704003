<template>
  <div v-observe-visibility="{
    callback: visible
  }
    " class="animation-container" :class="{ active: isActive }">
    <img src="@/assets/circle-bg.png" class="animation1">
    <img src="@/assets/logo.png" class="animation2">
    <img src="@/assets/circle1.png" class="animation3">
    <img src="@/assets/circle2.png" class="animation4">
    <img src="@/assets/circle3.png" class="animation5">
  </div>
</template>

<script>
export default {
  data() {
    return {
      isActive: false
    };
  },
  methods: {
    visible(isActive) {
      this.isActive = isActive;
    }
  }
};
</script>

<style lang="scss">
.animation-container {
  position: relative;
  height: 500px;
  max-width: 550px;
  margin: 60px auto;
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  .animation1,
  .animation2 {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    padding-top: 2rem;
  }

  @media screen and (max-width: 900px) {
    height: 45vh;
  }

  .animation1 {
    width: 1px;
    height: 1px;
    z-index: -9;
    opacity: 0;
    transition: all 500ms 0.4s ease-in-out;
  }

  .animation2 {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    transform: scale(1.3);
    transition: all 300ms 0.2s ease-in-out;
  }

  .animation3 {
    position: absolute;
    top: -3rem;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    opacity: 0;
    filter: saturate(40) hue-rotate(200deg);
  }

  .animation4 {
    position: absolute;
    bottom: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    opacity: 0;
    filter: saturate(40) hue-rotate(140deg);
  }

  .animation5 {
    position: absolute;
    right: 0;
    bottom: 0;
    margin-left: auto;
    margin-right: auto;
    opacity: 0;
    filter: saturate(40) hue-rotate(310deg);
  }
}

.animation-container.active {
  .animation1 {
    width: 100%;
    height: auto;
    opacity: 0.3;
  }

  .animation2 {
    transform: scale(0.5);
  }

  .animation3 {
    position: absolute;
    top: -3rem;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    opacity: 1;
    transition: all 500ms 0.7s ease-in-out;
    width: 8.5rem;
    padding: 5px;

    @media screen and (max-width: 900px) {
      width: 7rem;
    }
  }

  .animation4 {
    position: absolute;
    bottom: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    opacity: 1;
    transition: all 500ms 1s ease-in-out;
    width: 8.5rem;
    padding: 5px;

    @media screen and (max-width: 900px) {
      width: 7rem;
    }
  }

  .animation5 {
    position: absolute;
    right: 0;
    bottom: 0;
    margin-left: auto;
    margin-right: auto;
    opacity: 1;
    transition: all 500ms 1.3s ease-in-out;
    width: 8.5rem;
    padding: 5px;

    @media screen and (max-width: 900px) {
      width: 7rem;
    }
  }
}
</style>
