<template>
  <div>
    <navbar />
    <hero />
    <section class="separate-container" id="team">
      <h1><img src="@/assets/separate-single.png" alt="不動産のことは私たちにお任せください！" /></h1>
    </section>
    <section class="container text-content text-serif business-container" id="businesscontent">
      <div class="business-block">
        <h2>業務内容</h2>
        <p class="content">
          賃貸・売買・投資
          <br class="br" />すべて承ります。
          <br />ご満足いただける契約を
          <br class="br" />お約束いたします。
        </p>
      </div>
      <p class="content business-content">
        人間にとって【衣食住】は生活に欠かせません。そして、生きていく上で体を休め、心を癒し、そして明日への活力を生み出す原動力になるのが【住まい】です。快適な住環境は、幸せな人生の礎になります。だからこそ、お客様にはご満足いただけるご契約をしていただきたい。「この家を選んでよかった」と一生思っていただけるよう、賃貸だけでなく売買、そして投資までを、モットーに不動産業を営んでいます。不動産で、さらなる豊かさを築きたいという方は投資もご検討されることでしょう。その際にも、「失敗しない不動産投資」についてアドバイスをさせていただきながら、一緒に物件を探し、運用をお手伝いしていきたいと考えています。ぜひ、一緒に「不動産との幸せな付き合い方」をしていきましょう。
      </p>
    </section>
    <animation />
    <service />
    <youtube />
    <section class="about-container" id="about">
      <div class="about-block">
        <div>
          <h2>株式会社 エニシス</h2>
          <p>代表取締役 辻 健一</p>
        </div>
        <div>
          <h3>設立</h3>
          <p>
            2017年7月12日
            <br />宅地建物取引業
            <br />東京都知事(2)第101207号
          </p>
        </div>
        <div>
          <h3>取引金融機関</h3>
          <p>みずほ銀行 銀座中央支店</p>
          <p>東京シティ信用金庫 日本橋支店</p>
        </div>
      </div>
    </section>
    <!-- <div class="map-container gray-container">
      <div class="branch-container" data-office="headoffice">
        <div class="branch-item">
          <h3 class="branch-title">本店</h3>
          <p>
            〒103-0004
            <br />東京都中央区東日本橋2-4-4
            <br />東日本橋イーストビルB1F
          </p>
          <p>
            TEL:03-3527-2664
          </p>
        </div>
        <div class="branch-item">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3240.3884192720234!2d139.78350455162774!3d35.69205818009449!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018894cfd5f5acf%3A0x4c42661fd84cc538!2z44CSMTAzLTAwMDQg5p2x5Lqs6YO95Lit5aSu5Yy65p2x5pel5pys5qmL77yS5LiB55uu77yU4oiS77yU!5e0!3m2!1sja!2sjp!4v1558426045120!5m2!1sja!2sjp"
            frameborder="0" style="border:0" allowfullscreen class="map"></iframe>
        </div>
      </div>
      <div class="guide-block">
        <div class="guide-item">
          <img src="@/assets/map-4.jpg" class="guide-img" />
          <p class="guide-caption">この看板が目印です</p>
        </div>
        <div class="guide-item">
          <img src="@/assets/map-5.jpg" class="guide-img" />
          <p class="guide-caption">ＥＶ・階段どちらもご利用いただけます</p>
        </div>
      </div>
    </div> -->
    <div class="map-container gray-container" data-gray="2nd">
      <div class="branch-container">
        <div class="branch-item">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3240.285084844885!2d139.78174437697425!3d35.694601572582954!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188eb294d9af49%3A0x1228121215b25afd!2z44CSMTAzLTAwMDIg5p2x5Lqs6YO95Lit5aSu5Yy65pel5pys5qmL6aas5Zaw55S677yR5LiB55uu77yR77yS4oiS77yUIOaXqeW3neODk-ODqw!5e0!3m2!1sja!2sjp!4v1685453915789!5m2!1sja!2sjp"
            frameborder="0" style="border:0" allowfullscreen class="map"></iframe>
        </div>
        <div class="branch-item">
          <!-- <h3 class="branch-title">馬喰町店</h3> -->
          <p>
            〒103-0002
            <br>東京都中央区日本橋馬喰町1-12-4
            <br>早川ビル1F
          </p>
          <p>
            TEL:03-3527-2664
          </p>
        </div>
      </div>
      <div class="guide-block">
        <div class="guide-item">
          <img src="@/assets/66668.jpg" class="guide-img" />
          <p class="guide-caption">駅の出口すぐ真横です</p>
        </div>
        <div class="guide-item">
          <img src="@/assets/66667.jpg" class="guide-img" />
          <p class="guide-caption">明るい店内です！</p>
        </div>
      </div>
    </div>
    <div class="container handle-container">
      <div class="text-content text-serif">
        不動産のことならなんでも
        <br />お任せください。
        <br />ご相談だけでも構いません。
        <br />お茶を飲みながらの
        <br />不動産雑談だけでも
        <br />オッケーです。
        <br />お気軽にお問い合わせください！
        <p class="qr-block">
          <a href="http://line.me/ti/p/%40zln3483d#~" class="qr">
            <img src="@/assets/line.png" alt class="qr-code" />
          </a>
        </p>
      </div>
    </div>
    <inquiry id="inquiry" />
    <myfooter />
    <pagetop />
  </div>
</template>

<script>
import "normalize.css";

import hero from "./components/hero";
import navbar from "./components/NavBar";
import service from "./components/service";
import youtube from "./components/youtube";
import inquiry from "./components/inquiry";
import myfooter from "./components/footer";
import animation from "./components/animation";
import pagetop from "./components/pagetop";

export default {
  components: {
    hero,
    navbar,
    service,
    youtube,
    inquiry,
    animation,
    pagetop,
    myfooter
  },
  head: {
    title: {
      inner: "株式会社エニシス",
      separator: "|",
      complement: "enisys"
    },
    meta: [
      { charset: "utf-8" },
      { property: "og:type", content: "website" },
      { property: "og:image", content: "https://https://enisys-realestate.jp/img/enisys-thumbnail.jpg" },
      { property: "og:title", content: "株式会社エニシス" },
      { property: "og:site_name", content: "株式会社エニシス" },
    ],
    link: [
      { rel: "shortcut icon", href: "img/favicon.ico" },
      { rel: "apple-touch-icon", href: "img/touch-icon-iphone-60x60.png" },
      { rel: "apple-touch-icon", href: "img/touch-icon-ipad-76x76.png" },
      {
        rel: "apple-touch-icon",
        href: "img/touch-icon-iphone-retina-120x120.png"
      },
      {
        rel: "apple-touch-icon",
        href: "img/touch-icon-ipad-retina-152x152.png"
      }
      // ...
    ]
  }
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/earlyaccess/kokoro.css");
@import url("https://fonts.googleapis.com/css?family=Sawarabi+Mincho");

* {
  line-height: 1.6;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

p {
  margin: 0;
}

.container {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 4rem;
}

.hero-container,
.separate-container,
.animation-container {
  margin-bottom: 4rem;
}

.business-container {
  padding-bottom: 3rem;
}

.business-block {
  margin-bottom: 3rem;

  h2 {
    letter-spacing: 5px;
    font-size: 1.6rem;
    margin-bottom: 35px;
    position: relative;
    font-family: "Sawarabi Mincho", serif;
    font-weight: 100;

    &:before {
      position: absolute;
      display: block;
      bottom: -0.3rem;
      left: 0;
      right: 0;
      background-color: #c1272d;
      height: 5px;
      content: "";
      width: 5rem;
      text-align: center;
      margin: auto;
    }
  }

  .content {
    font-family: "Sawarabi Mincho", serif;
    font-weight: 100;
    font-size: 1.3rem;
    line-height: 1.8;
    letter-spacing: 0.1rem;
    text-align: center;
  }

  .br {
    display: none;

    @media (max-width:900px) {
      display: inline;
    }
  }
}

.qr-block {
  margin-top: 1rem;
}

.qr-code {
  max-width: 300px;
}

.separate-container {
  img {
    width: 100%;
  }
}

.about-container {
  background: url(~@/assets/window.jpg) center center no-repeat;
  background-size: cover;
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

.about-block {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 2rem;
  padding-bottom: 2rem;

  h2 {
    font-family: "Sawarabi Mincho", serif;
  }

  h3 {
    font-weight: 200;
    font-family: "Sawarabi Mincho", serif;
    margin-bottom: 0;
    font-size: inherit;
    letter-spacing: 0.1rem;
  }

  div {
    margin-bottom: 1.3rem;
  }

  p {
    font-family: "Sawarabi Mincho", serif;
    font-size: inherit;
    letter-spacing: 0.1rem;
    line-height: 1.8;
  }
}

.text-serif {
  font-family: "Sawarabi Mincho", serif;
}

.separate {
  img {
    width: 100%;
  }
}

.content {
  font-size: 16px;
  color: #707070;
  text-align: justify;
}

.text-content {
  text-align: center;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.gray-container {
  background-color: #f4f4f4;

  &[data-gray="2nd"] {
    background-color: #e7e7e7;
  }
}

.map-container {
  padding-top: 3rem;
  padding-bottom: 3rem;
  text-align: center;

}

.map {
  width: 100%;
  max-width: 1000px;
  height: 300px;
}

.branch {
  &-title {
    position: relative;
    padding-bottom: 1em;
    margin-bottom: 1em;

    &:before {
      content: "";
      display: block;
      position: absolute;
      bottom: 1rem;
      right: 0;
      left: 0;
      bottom: 0;
      margin-left: auto;
      margin-right: auto;
      border-bottom: dotted 7px #cbc83d;
      height: 1rem;
      width: 50px;
    }
  }

  &-container {
    display: flex;
    align-items: center;
    width: min(100%, 1000px);
    margin-inline: auto;

    @media (max-width: 900px) {
      flex-direction: column-reverse;

      &[data-office="headoffice"] {
        flex-direction: column;
      }
    }


  }

  &-item {
    width: 50%;

    @media (max-width: 900px) {
      width: 100%;
    }

    p {
      margin-bottom: 1em;
    }
  }
}


.handle-container {
  .text-content {
    font-size: 1.8rem;
    font-weight: 100;
    line-height: 1.8;
    margin-top: 2em;

    @media screen and (max-width: 900px) {
      font-size: 1.4rem;
    }
  }
}

::-webkit-scrollbar {
  display: none;
  -webkit-appearance: none;
}

.guide-block {
  max-width: 1000px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;

  .guide-item {
    width: 46%;
    margin-top: 1rem;
  }

  .guide-img {
    width: 100%;
  }

  .guide-caption {
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    font-size: small;
  }

  @media screen and (max-width: 900px) {
    flex-flow: column;

    .guide-item {
      width: 100%;
      padding: 0;
    }
  }
}
</style>
