import Vue from 'vue';
import App from './App.vue';
import VueObserveVisibility from 'vue-observe-visibility';
import VueScrollTo from 'vue-scrollto';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VeeValidate from 'vee-validate';
import VueHead from 'vue-head';

Vue.use(VeeValidate);
Vue.use(VueAxios, axios);
Vue.use(VueObserveVisibility);
Vue.use(VueHead);
Vue.use(VueScrollTo, {
  container: "body",
  duration: 700,
  easing: "ease-out",
  offset: -50
});

Vue.config.productionTip = false;

new Vue({
  render: h => h(App)
}).$mount('#app');
