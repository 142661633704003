<template>
  <div class="nav-container" :class="{open: isOpen}">
    <button class="nav-button" type="button" name="button" @click="navbutton">
      <span class="top"></span>
      <span class="middle"></span>
      <span class="bottom"></span>
    </button>
    <nav class="nav">
      <a v-scroll-to="'body'" class="nav-logo">
        <img src="@/assets/white-logo.png" alt="株式会社えにしす">
      </a>
      <ul class="nav-list">
        <li class="nav-item">
          <a v-scroll-to="'body'" @click="navbutton">Top</a>
        </li>
        <li class="nav-item">
          <a v-scroll-to="'#team'" @click="navbutton">Team</a>
        </li>
        <li class="nav-item">
          <a v-scroll-to="'#businesscontent'" @click="navbutton">Business</a>
        </li>
        <li class="nav-item">
          <a v-scroll-to="'#about'" @click="navbutton">About</a>
        </li>
        <li class="nav-item">
          <a v-scroll-to="'#inquiry'" @click="navbutton">Contact</a>
        </li>
        <li class="nav-item add-item">
          <a href="tel:03-3527-2664">03-3527-2664</a>
        </li>
        <li class="nav-item add-item">
          <a href="mailto:info@enisys-realestate.jp">info@enisys-realestate.jp</a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false
    };
  },
  methods: {
    navbutton() {
      this.isOpen = !this.isOpen;
    }
  }
};
</script>

<style lang="scss">
.nav-container {
  background-color: #c1272d;
  position: fixed;
  z-index: 99;
  @media screen and (max-width: 900px) {
    .nav-list {
      display: none;
      opacity: 0;
      animation: show 0.5s linear 0s;
    }
    &.open {
      .nav {
        flex-direction: column;
        justify-content: flex-start;
        height: 100vh;
      }
      .nav-list {
        display: block;
        opacity: 1;
      }
    }
  }
}

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.add-item {
  display: none;
  @media screen and (max-width: 900px) {
    display: block;
  }
}

.nav-logo {
  margin-left: 2rem;
  margin-right: 2rem;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 36px;
  img {
    width: 100%;
    vertical-align: middle;
    object-fit: contain;
  }
  @media screen and (max-width: 900px) {
    margin-left: auto;
    margin-right: auto;
    background-color: #c1272d;
  }
}
.nav {
  position: fixed;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  top: 0;
  text-align: center;
  height: 50px;
  background-color: #c1272d;
  z-index: 9;
  @media screen and (max-width: 900px) {
    background-color: #c1272d;
  }
}

.nav-list {
  display: flex;
  justify-content: space-evenly;
  background-color: #c1272d;
  list-style: none;
  width: 100%;
  margin: 0;
  padding: 0;
  transition-duration: 0.5s ease;
  @media screen and (max-width: 900px) {
    justify-content: flex-start;
    flex-direction: column;
    height: 100vh;
  }
}

.nav-item {
  width: 100%;

  a {
    cursor: pointer;
    width: 100%;
    display: block;
    line-height: 50px;
    transition-duration: 0.3s;
    color: white;
    &:hover {
      background-color: white;
      color: #c1272d;
    }
  }
  @media screen and (max-width: 900px) {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

.input-color {
  margin-bottom: 10px;
  position: relative;
  display: block;
}

.myNavbar {
  color: rgb(255, 255, 255);
}

.nav-button {
  display: none;
}

@media screen and (max-width: 900px) {
  .nav-button {
    display: block;
    width: 50px;
    height: 50px;
    background-color: transparent;
    border: none;
    position: absolute;
    z-index: 100;
    appearance: none;
    cursor: pointer;
    outline: none;

    span {
      display: block;
      width: 20px;
      height: 2px;
      margin: auto;
      background-color: white;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      transition: 0.5s ease;
      &.top {
        transform: translateY(-8px);
      }
      &.bottom {
        transform: translateY(8px);
      }
    }
  }

  .open {
    .nav-button {
      .top {
        transform: rotate(45deg);
      }
      .middle {
        opacity: 0;
      }
      .bottom {
        transform: rotate(-45deg);
      }
    }
  }
}
</style>