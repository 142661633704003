<template>
  <section class="gray-container inquiry-container">
    <div class="inquiry-block">
      <h2>お問い合わせ</h2>
      <form @submit.prevent="onsubmit">
        <div class="input-block">
          <label>
            <span>
              お名前
              <i>*</i>
            </span>
            <input type="text" class="input" v-model="name" placeholder="お名前" required>
          </label>
        </div>
        <div class="input-block">
          <label>
            <span>
              フリガナ
              <i>*</i>
            </span>
            <input type="text" class="input" v-model="kana" placeholder="フリガナ" required>
          </label>
        </div>
        <div class="input-block">
          <label>
            <span>
              電話番号
              <i>*</i>
            </span>
            <input class="input" type="tel" v-model="tel" placeholder="000-0000-0000" required>
          </label>
        </div>
        <div class="input-block">
          <label>
            <span>
              メールアドレス
              <i>*</i>
            </span>
            <input class="input" type="email" v-model="email" placeholder="xxx@xxx.com">
          </label>
        </div>
        <div class="input-block">
          <p>
            お問い合わせ内容
            <i>*</i>（複数回答可）
          </p>
          <p>
            <label for="intention1">
              <input type="checkbox" name="intention" value="物件を借りたい" id="intention1" v-model="intention">物件を借りたい
            </label>
          </p>
          <p>
            <label for="intention2">
              <input type="checkbox" name="intention" value="物件を貸したい" id="intention2" v-model="intention">
              物件を貸したい
            </label>
          </p>
          <p>
            <label for="intention3">
              <input type="checkbox" name="intention" value="物件を買いたい" id="intention3" v-model="intention">
              物件を買いたい
            </label>
          </p>
          <p>
            <label for="intention4">
              <input type="checkbox" name="intention" value="物件を売りたい" id="intention4" v-model="intention">
              物件を売りたい
            </label>
          </p>
        </div>
        <div>
          <p>その他お問い合わせ</p>
          <textarea name id rows="10" v-model="message"></textarea>
        </div>

        <div>
          <small>※利用規約及びプライバシーポリシーを必ずお読みください。記載内容に同意頂いた場合は、弊社へのお問合せ内容をお送りくださいませ。</small>
          <small>
            <a @click="openTerms">利用規約</a>
          </small>
          <small>
            <a @click="openPp">プライバシーポリシー</a>
          </small>
        </div>

        <div class="form-button">
          <button type="reset" class="button reset-button">リセット</button>
          <button type="submit" class="button submit-button">送信</button>
        </div>
      </form>
      <transition name="success">
        <div class="success-block" v-show="success">
          お問い合わせを受付いたしました。
          <br>改めて担当者よりご連絡をさせていただきます。
        </div>
      </transition>
    </div>

    <transition name="modal" appear v-if="myterms" key="terms">
      <div class="modal modal-overlay" @click="closeTerms">
        <div class="modal-window">
          <div class="modal-content">
            <p>
              株式会社エニシス（以下、「当社」）は、当社の各事業を行うにあたり、お客様の個人情報を識別することが可能な情報（以下、「個人情報」）について、これを適切に保護することが当社の社会的責務と考えております。お客様の信頼にお応えするため、事業を通して取得し利用する個人情報の取扱いに関し、以下のように「個人情報保護方針」を制定し、すべての役員及び従業員が当方針の実現に取り組んでまいります。
            </p>
            <ol>
              <li>
                個人情報保護の方針
                <br>当社は個人情報保護に関する法令と社会秩序を尊重・厳守し、個人情報の適正な取扱いと保護に努めます。
              </li>
              <li>
                個人情報の定義
                <br>個人情報とは、お客様の氏名、生年月日、お電話番号、勤務先等の属性情報、E-Mailアドレス、ご住所、連帯保証人予定者の情報、その他お客様から提供を受けた情報において、1つまたは複数を組合わせることにより、お客様個人を特定することのできる情報をいいます。
              </li>
              <li>
                個人情報の取得、利用、提供
                <br>個人情報の取得は、適正な手段によって行うとともに、利用目的の公表、通知、明示等をさせていただき、ご本人の同意なく、利用目的の範囲を超えた個人情報の取扱いはいたしません。また、個人情報を第三者へ提供・開示等する場合は、法令の定める手続きに則って行います。
              </li>
              <li>
                個人情報の利用目的
                <br>
                <ol>
                  <li>不動産の売買、賃貸、仲介、管理等の取引に関する契約の履行、及び情報、サービスの提供。</li>
                  <li>上記１の利用目的の達成に必要な範囲での、個人情報の第三者への提供。</li>
                  <li>当社が取り扱う商品に関する契約の履行、情報、サービスの提供。</li>
                  <li>上記１、３の商品・情報・サービス提供のための郵便物、電話、電子メール等による営業活動、及びアンケートのお願い等のマーケティング活動、顧客動向分析または商品開発等の調査分析。</li>
                </ol>情報、サービスの提供は、ご本人からの申出がありましたら取り止めさせていただきます。
              </li>
              <li>
                個人情報の第三者への提供
                <br>当社が保有する個人情報は、以下の場合に、第三者へ提供されます。
                <ol>
                  <li>ご本人の同意がある場合。</li>
                  <li>法令の規定に基づく場合。</li>
                  <li>人の生命、身体または財産の保護のため必要がある場合であって、ご本人の同意を得ることが困難である場合。</li>
                  <li>公衆衛生の向上または児童の健全な育成の推進のため特に必要がある場合であって、ご本人の同意を得ることが困難であるとき。</li>
                  <li>国の機関もしくは地方公共団体、またはその委託を受けたものが法令の定める事務を遂行することに対して協力する必要がある場合であって、ご本人の同意を得ることにより当該事務の遂行に支障を及ぼす恐れがあるとき。
                  </li>
                  <li>利用目的の達成に必要な範囲で、機密保持契約を締結している信頼出来る業務委託先に対し、必要な範囲で開示する場合。</li>
                </ol>《不動産物件情報を第三者提供（広告）する場合》
                <ol>
                  <li>広告を行う不動産物件情報は、物件種目、所在地、価格、交通、土地及び建物の面積、間取、設備、写真、案内図等であり、個人の氏名は含みません。</li>
                  <li>
                    指定流通機構への登録、インターネット、不動産情報誌、チラシ等の広告媒体を通じて直接、または他の不動産会社を通じて間接的（当社の同意のもと、他の不動産会社が広告を行う場合等を含む）に、契約の相手方や売買・賃貸借希望者に提供されます。
                  </li>
                  <li>契約が成立した場合は、速やかに成約報告（成約年月日、価格）を広告媒体主等へ行い、広告を停止します。
                    成約情報は、指定流通機構や民間の広告媒体主により集計、加工もしくは分析され、他の取引における価格査定の資料等として利用されます。</li>
                </ol>
              </li>
              <li>
                個人情報の安全管理措置
                <br>当社が有する個人情報は適正かつ慎重に管理し、個人情報への不正アクセス、紛失、改ざん、漏えい等を防止するため、必要かつ適切な安全管理措置を講じます。
              </li>
              <li>
                個人情報取扱の委託
                <br>当社が有する個人情報について、その取扱いを外部に委託する場合があります。その場合は、個人情報の保護に十分な措置が講じている者を選定し、委託先に対し必要かつ適切な監督を行います。
              </li>
              <li>
                個人情報の開示・訂正等の手続き
                <br>当社が管理する個人情報の開示、訂正、利用停止等に関するご本人からの要請については、本人であることを確認した上で、速やかに、合理的な範囲で必要な対応をします。
              </li>
              <li>
                他のサイトへのリンク
                <br>当ＷＥＢサイトには第三者のＷＥＢサイトへのリンクがありますが、当社は、これら第三者のＷＥＢサイトにおけるプライバシー保護に関しての責任は負いませんので、予めご了承ください。
              </li>
              <li>
                個人情報保護に関する問合せ先
                <br>当社の個人情報の取り扱い及び管理に関するお問合せ先は、以下の通りです。
              </li>
            </ol>
            <p>
              株式会社エニシス
              <br>電話：03-3527-2663
              <br>FAX：03-3527-2665
            </p>
          </div>
          <footer class="modal-footer">
            <button @click="closeTerms">Close</button>
          </footer>
        </div>
      </div>
    </transition>

    <transition name="modal" appear v-if="mypp" key="pp">
      <div class="modal modal-overlay" @click="closePp">
        <div class="modal-window">
          <div class="modal-content">
            <p>第1条（本サービスについて）</p>
            <ol>
              <li>利用者は本サービス利用にあたり、自己の責任と負担において通信機器、ソフトウェア、回線環境等設備を用意するものとします。</li>
              <li>本サービスは、本サイトを通して行う情報の提供、およびその他の情報の提供から構成されます。</li>
            </ol>
            <p>第2条（本規約の変更）</p>
            <ol>
              <li>当社は、利用者の承諾を得ることなく、当社が定める方法により、本規約を変更することができるものとします。この場合、提供条件等は変更後の規約によります。</li>
              <li>変更後の規約は、当社が変更内容を本サービスのページ上に掲示した時点から効力を有するものとします。</li>
            </ol>
            <p>第3条（当社からの通知）</p>
            <ol>
              <li>当社は、本サービスのページへの掲示、その他当社が適当と判断する方法・範囲で、利用者が本サービスを利用するうえで必要な事項を通知するものとします。</li>
              <li>前項に定める通知は、当社が当該通知の内容を、本サービスのページに掲示した時点から効力を有するものとします。</li>
            </ol>

            <p>第4条（著作権）</p>
            <p>本サービスにおいて当社が提供するページ等のコンテンツ、画面デザインその他一切の著作物の著作権は、当社又は当社が定める者に帰属するものとします。</p>
            <p>第5条（利用契約の締結等）</p>
            <ol>
              <li>利用者は、本サービスを利用するにあたり必要な情報を入力、当社宛に発信することにより本サービスの申し込みをするものとし、当該発信情報が本サービスのサーバに格納された時点で、利用契約が成立するものとします。
              </li>
              <li>
                当社は、前項その他本規約の規定にかかわらず、利用者が次のいずれかに該当する場合には、利用契約を締結しないことがあります。
                <ol>
                  <li>本サービスの申し込みをした方が実在しないことが判明したとき</li>
                  <li>本サービスの申し込み時に、虚偽の入力、入力誤りがあったとき又は入力もれがあったとき</li>
                  <li>その他、当社が不適当と判断したとき</li>
                </ol>
              </li>
            </ol>
            <p>第6条（本サービスの料金）</p>
            <p>本サービスの利用料金は、無料とします。</p>
            <p>第7条（中断・廃止）</p>
            <p>当社は、次のいずれかに該当すると判断した場合、利用者への事前の連絡又は承諾を要することなく、本サービスを一時的に中断できるものとします。</p>
            <ol>
              <li>本サービスの提供に必要な設備の故障等により保守を行う場合</li>
              <li>不可抗力により本サービスを提供できない場合</li>
              <li>本サービスの提供に必要なデータのバックアップ等を行なう場合</li>
              <li>その他、運用上又は技術上の理由でやむを得ない場合</li>
            </ol>
            <p>第8条 （広告等）</p>
            <p>
              当社は、本サービスのページ上に第三者の提供する広告を掲載することがあります。なお、当該広告は広告提供者の責任で掲載されるものであって、当社はその正確性、適法性等について保証するものではなく、一切責任を負わないものとします。
            </p>
            <p>第9条（個人情報）</p>
            <p>当社は、本サービスにおける個人を特定する情報(以下｢個人情報｣といいます。)は、以下のとおり取り扱うものとします。</p>
            <ol>
              <li>当社取扱商品に関するご連絡、ご通知、資料送付、契約の履行、資料情報、サービスの提供。</li>
              <li>当社からの情報提供の為</li>
              <li>当社取扱商品の情報、キャンペーン情報（広告含む）のご案内、資料送付の為</li>
              <li>お問合せいただいた「ご質問・ご意見等」に対して、当社よりご連絡をさせていただく為</li>
              <li>本サービスのご利用においてのお問合せ・発生したトラブル等に対して、当社よりご連絡させていただく為</li>
              <li>本サービスを円滑に運営する為に一定期間の保管をする為</li>
              <li>当社のマーケティングデータとして、個人が特定できない形での利用をする為</li>
            </ol>
            <p>第10条（禁止行為）</p>
            <p>利用者は、本サービスの利用にあたり、次の各項に定める行為をしてはならないものとします。</p>
            <ol>
              <li>本サービスに関する情報を改ざんする行為</li>
              <li>利用者以外の者になりすまして本サービスを利用する行為</li>
              <li>有害なコンピュータープログラム等を送信又は書き込む行為</li>
              <li>第三者又は当社の財産、名誉及びプライバシー等を侵害する行為</li>
              <li>本人の同意を得ることなく又は詐欺的な手段により第三者又は当社の個人情報を収集する行為</li>
              <li>本サービスの利用又は提供を妨げる行為</li>
              <li>第三者又は当社の著作権その他の知的財産権を侵害する行為</li>
              <li>法令又は公序良俗に反する行為</li>
              <li>本サービスを利用した営業活動その他営利を目的とする行為</li>
              <li>当社の信用を傷つけ、又は当社に損害を与える行為</li>
              <li>その他、当社が不適切と判断した行為</li>
            </ol>
            <p>第11条（責任の範囲）</p>
            <p>当社は、第5条又は次の各項に定める事由により、利用者又は第三者（他の利用者を含む）が損害を被った場合であっても、一切責任を負わないものとします。</p>
            <ol>
              <li>利用者設備の不具合等により、本サービスを利用できなかった場合</li>
              <li>利用者が本規約に違反した場合</li>
              <li>紛争又は損害の原因が当社の責に帰すべき事由による場合を除き、本サービスの利用によって利用者と第三者との間で紛争が生じた場合又は利用者が第三者に損害を与えた場合</li>
              <li>その他本サービスを利用することにより、当社の責によらずして利用者又は第三者に損害が発生した場合</li>
            </ol>
            <p>第12条（本規約に定めのない事項）</p>
            <p>本規約に定めのない事項その他本規約の条項に関し疑義を生じたときは、協議のうえ円満に解決を図るものとします。</p>
            <p>第13条（問合せ先）</p>
            <p>本サービスにかかる問合せ先は、以下の通りです。</p>
            <p>
              株式会社エニシス
              <br>電話：03-3527-2663
              <br>FAX：03-3527-2665
            </p>
          </div>
          <footer class="modal-footer">
            <button @click="closePp">Close</button>
          </footer>
        </div>
      </div>
    </transition>
  </section>
</template>

<script>
export default {
  data() {
    return {
      myterms: false,
      mypp: false,
      success: false,
      name: "",
      kana: "",
      tel: "",
      email: "",
      intention: [],
      message: ""
    };
  },
  methods: {
    openTerms() {
      this.myterms = true;
    },
    closeTerms() {
      this.myterms = false;
    },
    openPp() {
      this.mypp = true;
    },
    closePp() {
      this.mypp = false;
    },
    onsubmit() {
      let data = new FormData();

      data.append("name", this.name);
      data.append("kana", this.kana);
      data.append("tel", this.tel);
      data.append("email", this.email);
      data.append("intention", this.intention);
      data.append("message", this.message);

      this.$http.post("form.php", data).then(response => {
        const items = response.data;
        // eslint-disable-next-line
        console.log(items);
        this.success = true;
      });
    }
  }
};
</script>

<style lang="scss">
.inquiry-container {
  padding-top: 2rem;
  padding-bottom: 3rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  .inquiry-block {
    width: 100%;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }

  h2 {
    text-align: center;
    font-family: "Sawarabi Mincho", serif;
    font-weight: 200;
    font-size: 1.8rem;
    position: relative;

    &:before {
      position: absolute;
      display: block;
      bottom: -0.3rem;
      left: 0;
      right: 0;
      background-color: #c1272d;
      height: 5px;
      content: "";
      width: 5rem;
      text-align: center;
      margin: auto;
    }
  }

  span {
    display: block;
    margin-bottom: 0.2rem;
  }

  .input-block {
    margin-bottom: 1rem;
  }

  i {
    color: #c1272d;
  }

  input {
    width: 100%;
    line-height: 2.5rem;
    padding-left: 0.5rem;
    box-sizing: border-box;
  }

  input[type="checkbox"] {
    width: auto;
    margin-right: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  textarea {
    width: 100%;
    box-sizing: border-box;
  }

  small {
    text-align: justify;
  }

  a {
    text-decoration: underline;
    margin-right: 1rem;
    cursor: pointer;
  }
}

.form-button {
  display: flex;
  justify-content: space-around;
  margin-top: 3rem;

  .button {
    background: none;
    padding: 0.5rem 1.5rem;
    width: 10rem;
  }

  .reset-button {
    border: solid 2px gray;
    color: gray;
  }

  .submit-button {
    border: solid 2px #0e5f8e;
    color: #0e5f8e;
  }
}
</style>

<style lang="scss" scoped>
.modal-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 30;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  overflow: auto;
}

.modal-window {
  background: #fff;
  border-radius: 4px;
  width: 90%;
  height: 90%;
  overflow: auto;
}

.modal-content {
  padding: 30px 20px;
  font-size: 0.75rem;
  overflow: auto;
  line-height: 1.2;
}

.modal-footer {
  background: #ccc;
  padding: 10px;
  text-align: right;
  z-index: 9;
}

// オーバーレイのトランジション
.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.4s;

  // オーバーレイに包含されているモーダルウィンドウのトランジション
  .modal-window {
    transition: opacity 0.4s, transform 0.4s;
  }
}

// ディレイを付けるとモーダルウィンドウが消えた後にオーバーレイが消える
.modal-leave-active {
  transition: opacity 0.6s ease 0.4s;
}

.modal-enter,
.modal-leave-to {
  opacity: 0;

  .modal-window {
    opacity: 0;
    transform: translateY(-20px);
  }
}

.success-block {
  text-align: center;
  background-color: white;
  margin-top: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.success-enter-active,
.success-leave-active {
  text-align: center;
  transition: 0.5s ease;
}

.success-leave-active {
  position: absolute;
}

.success-enter,
.success-leave-to {
  opacity: 0;
}

.success-enter-to,
.success-leave {
  opacity: 1;
}
</style>