<template>
  <button @click="$emit('input', id)" class="btn" :class="[active, 'tab'+ this.id]"></button>
</template>

<script>
export default {
  props: {
    id: Number,
    label: String,
    value: Number
  },
  computed: {
    active() {
      return this.value === this.id ? "active" + this.id : false;
    }
  }
};
</script>

<style scoped>
.tab {
}
.tab:hover {
  background: #eeeeee;
}
.active {
  background: #f7c9c9;
}
</style>