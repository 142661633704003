<template>
  <section class="container service-container">
    <div v-for="item in list" v-bind="item" v-bind:key="item.id" class="block">
      <h2 class="text-serif">{{ item.title }}</h2>
      <p class="content">{{ item.content }}</p>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          id: 1,
          title: "賃貸仲介",
          content:
            "私たちはお部屋を紹介する会社ではありません。 不動産を通じて充実した日々をお過ごしいただくためのプロフェッショナル集団です。 お引越しをご検討されいる方は楽しみと不安が入り混じる不思議な気持ちを 抱かれているはず、その不安な部分を、すべてエニシスは受け止めます。 【お引越しに慣れている方などおりません】なんでも聞いてください。 南向きより、貴方向きのお部屋をご紹介いたします。"
        },
        {
          id: 2,
          title: "売買仲介",
          content:
            "【半住半投】 一生に、一度の大きなお買い物！それが、不動産の購入と言われおります。しかし、一生に一度ではなかった場合はどうでしょうか？半分は住まわれるために考えて、もう半分は投資の目線を考えをもつということそれが【半住半投】です。エニシスだからこそ、入居者様が入りやすいマンションの特徴をお伝えできます。資産価値が下がりづらいエリアやマンションの特徴をお伝えできます。他社ではできないご売却の方法ができます。はじめてのご購入の方も、お買い換えの方も、ご売却を検討されている方も お気軽にお問合せくださいませ。"
        },
        {
          id: 3,
          title: "投資・管理",
          content:
            "不動産投資に興味はあるけど、なんとなく「コワい」、漠然とした「不安」をかかえて踏み出せない。 そんなあなたの「コワい」や「不安」を是非、お聞かせください。実際に、不動産で資産形成を行っている スタッフが包み隠さず、すべてお伝えし、すべてお見せ致します。（ここまで見せてしまうの？と思うほど） 【ほんとうに不動産で幸せになっていただきたい】と思う弊社の気持ちが、お伝えできればと思います。また、ご購入後の運用やアドバイスもさせて頂いております。お気軽にお声掛けくださいませ。"
        }
      ]
    };
  }
};
</script>

<style scoped lang="scss">
.text-serif {
  font-size: 1.6rem;
  display: inline-block;
  position: relative;
  font-family: "Sawarabi Mincho", serif;
  font-weight: 100;

  &:before {
    position: absolute;
    display: block;
    bottom: -0.3rem;
    right: 0;
    background-color: #c1272d;
    height: 5px;
    content: "";
    width: 120%;
    text-align: center;
  }
}

.service-container {
  &>.block {
    padding-bottom: 4rem;
  }

  &>.block:not(:last-of-type) {
    position: relative;

    &:before {
      content: "";
      display: block;
      position: absolute;
      bottom: 1rem;
      right: 0;
      left: 0;
      margin-left: auto;
      margin-right: auto;
      border-bottom: dotted 7px #cbc83d;
      height: 1rem;
      width: 80%;
    }
  }
}
</style>
