<template>
  <section class="youtube-container gray-container">
    <div v-for="item in movieList" :key="item.id" class="youtube-block">
      <iframe
        :src="item.url"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        class="movie"
      ></iframe>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      movieList: [
        {
          id: 1,
          url: "https://www.youtube.com/embed/_xLkQ1MVn4g",
          title: "物件探しに本気「株式会社エニシス 」"
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.youtube-container {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.youtube-block {
  max-width: 1000px;
  margin: auto;
  position: relative;
  padding-bottom: 45%;
  height: 0;
  overflow: hidden;
  @media screen and (max-width: 900px) {
    flex-direction: column;
    padding-bottom: 56.25%;
  }
}
.movie {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.youtube-caption {
  background-color: white;
  padding: 1rem 1.5rem;
  a {
    color: #707070;
  }
}
</style>

