<template>
  <div class="pagetop" v-cloak>
    <transition name="pagetop">
      <p v-scroll-to="'body'" v-show="scY > 500">▲</p>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      scTimer: 0,
      scY: 0
    };
  },
  created: function() {
    window.addEventListener("scroll", this.scEvent);
  },
  methods: {
    scEvent: function() {
      if (this.scTimer) return;
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY;

        clearTimeout(this.scTimer);
        this.scTimer = 0;
      }, 100);
    }
  }
};
</script>

<style lang="scss">
.pagetop {
  position: fixed;
  bottom: 2rem;
  right: 5rem;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.8s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
  p {
    text-decoration: none;
    color: white;
    font-size: 25px;
    background-color: #cbc83d;
    line-height: 25px;
    padding: 10px;
    transition: 0.3s ease;
  }
  @media screen and (max-width: 900px) {
    right: 2rem;
  }
}
.pagetop-enter,
.pagetop-leave-to {
  opacity: 0;
}

.pagetop-enter-to,
.pagetop-leave {
  opacity: 1;
}
</style>